import React from 'react';

import { graphql } from 'gatsby';

import DefaultLayout from '../layouts';
import ContentSection from '../layouts/content-section';
import Metadata from '../components/metadata';
import NewsList from '../components/news-list';
// Import PostSummary query fragment from NewsItem
// eslint-disable-next-line
import NewsItem from '../components/news-item';
import Pagination from '../components/pagination';

const NewsIndexTemplate = ({ data, pageContext, location }) => {
  const newsData = data.allWpPost.edges.map(({ node }) => node);
  const { currentPage } = pageContext;
  const pageTitle =
    currentPage === 1 ? 'Uutiset' : `Uutiset, sivu ${currentPage}`;

  return (
    <DefaultLayout>
      <Metadata
        page={{
          title: pageTitle,
          excerpt: '',
        }}
        location={location}
      />

      <ContentSection>
        <h1>Uutiset</h1>

        <NewsList items={newsData} />

        <Pagination paginationData={pageContext} basePath="/uutiset" />
      </ContentSection>
    </DefaultLayout>
  );
};

export default NewsIndexTemplate;

export const pageQuery = graphql`
  query NewsListQuery($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...PostSummary
        }
      }
    }
  }
`;
