import React from 'react';

import { Link } from 'gatsby';

import * as paginationStyles from './pagination.module.css';

export const pathForPage = (basePath, pageNum) =>
  pageNum === 1 ? basePath : `${basePath}/${pageNum}`;

const range = (start, stop) =>
  Array.from({ length: stop - start }, (_, i) => start + i);

const PageLink = ({ basePath, pageNum, currentPage }) => {
  const path = pathForPage(basePath, pageNum);

  return pageNum === currentPage ? (
    <span className={paginationStyles.currentPaginationItem}>{pageNum}</span>
  ) : (
    <Link to={path} className={paginationStyles.paginationItem}>
      {pageNum}
    </Link>
  );
};

const Pagination = ({ paginationData, basePath }) => {
  const { currentPage, numPages } = paginationData;
  const prevPath = pathForPage(basePath, currentPage - 1);
  const nextPath = pathForPage(basePath, currentPage + 1);

  const prevPagesCount = Math.min(6, currentPage) - 1;
  const nextPagesCount = 10 - prevPagesCount;
  const pageNums = range(
    currentPage - prevPagesCount,
    Math.min(currentPage + nextPagesCount, numPages + 1)
  );

  const morePrevPages = pageNums[0] !== 1;
  const moreNextPages = pageNums[pageNums.length - 1] !== numPages;

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={paginationStyles.pagination}>
        {currentPage > 1 && (
          <Link to={prevPath} className={paginationStyles.paginationItem}>
            «
          </Link>
        )}

        {morePrevPages && (
          <span className={paginationStyles.paginationItem}>…</span>
        )}

        {pageNums.map((pageNum) => (
          <PageLink
            basePath={basePath}
            pageNum={pageNum}
            currentPage={currentPage}
          />
        ))}

        {moreNextPages && (
          <span className={paginationStyles.paginationItem}>…</span>
        )}

        {currentPage < numPages && (
          <Link to={nextPath} className={paginationStyles.paginationItem}>
            »
          </Link>
        )}
      </div>
    </div>
  );
};

export default Pagination;
