import React from 'react';

import { Link } from 'gatsby';

import * as listStyles from './news-list.module.css';
import NewsItem from './news-item';

const NewsList = ({ items, showMoreLink }) => {
  return (
    <div className={listStyles.newsList}>
      {items.map((item) => (
        <NewsItem key={item.uri} item={item} />
      ))}

      {showMoreLink && (
        <div className={listStyles.moreNews}>
          <h3>
            <Link to="/uutiset">Lisää uutisia »</Link>
          </h3>
        </div>
      )}
    </div>
  );
};

export default NewsList;
